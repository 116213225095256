<template>
      <div v-if="formVisible">
        <el-dialog
          v-dragDialog
          :title="formTitle"
          :visible.sync="formVisible"
          width="600px"
          :close-on-click-modal="false"
        >
          <el-form
            :model="dataForm"
            ref="dataForm"
            label-width="150px"
            :rules="dataFormRules"
          >
            <el-form-item label="开放项目：" prop="CheckTypes">
              <el-select
                style="width: 100%"
                size="small"
                v-model="dataForm.CheckTypes"
                multiple
                placeholder="请选择开放测试项目"
              >
                <el-option
                  v-for="item in $config.checkType"
                  :key="item.key"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false">取消</el-button>
            <el-button type="primary" @click="handleSubmit">提交</el-button>
          </div>
        </el-dialog>
      </div>
  </template>
  <script>
  import regionSelector2 from "@/components/regionSelector2";
  import {
    saveCheck,
  } from "@/api/check/check.js";
  export default {
    components: { regionSelector2 },
    data() {
      return {
        formVisible: false,
        formTitle: "创建",
        dataForm: {},
        dataFormRules: {

        },
      };
    },
    methods: {
      handleEdit(row) {
        this.formTitle = "修改预约项目";
        this.dataForm = JSON.parse(JSON.stringify(row));
        this.dataForm.CheckTypes =this.checkTypesToArray(row.CheckTypes);
        this.formVisible = true;
      },
      handleSubmit() {
        this.$refs.dataForm.validate((valid) => {
          if (valid) {
            let param = JSON.parse(JSON.stringify(this.dataForm));
            param.CheckTypes = this.arrayToValue(this.dataForm.CheckTypes);
            saveCheck(param).then((res) => {
              if (res) {
                this.formVisible = false;
                this.$emit("update")
              }
            });
          }
        });
      },
      arrayToValue(vals) {
        let value = 0;
        if (vals && vals.length > 0) {
          vals.forEach((r) => {
            value = value | r;
          });
        }
        return value;
      },
      checkTypesToArray(val) {
        let arrays = [];
        this.$config.checkType.forEach((r) => {
          if ((val & r.value) == r.value) {
            arrays.push(r.value);
          }
        });
        return arrays;
      },
    },
    mounted() {
    },
  };
  </script>