<template>
  <div style="width: 100%">
    <el-table border size="mini" :data="datas" style="width: 100%">
      <el-table-column prop="Code" fixed="left" label="门店" width="120">
        <template slot-scope="scope">
          {{ scope.row.Code }}
          <el-tag type="warning">{{ scope.row.Tenant.Name }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="客户" fixed="left" width="130">
        <template slot-scope="scope">
          <div
            class="link"
            @click="toCustomerForm(scope.row.Member.Key)"
            v-if="scope.row.Member"
          >
            {{ scope.row.Member.RealName }}
          </div>
          <el-tag type="info">{{ scope.row.Member.Mobile }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="Member.Sex" label="性别" :width="60">
      </el-table-column>
      <el-table-column prop="BMI" label="BMI" :min-width="70">
        <template slot-scope="scope">
          {{ scope.row.BMI.toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column prop="FatRate" label="体脂率" :min-width="70">
      </el-table-column>
      <el-table-column label="血压" :min-width="120">
        <template slot-scope="scope">
          <el-tag type="warning">{{ scope.row.BloodPressureMin }}</el-tag
          >/
          <el-tag type="danger">{{ scope.row.BloodPressureMax }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="预约日期" width="120">
        <template slot-scope="scope">
          <el-tag type="warning">{{
            format.dateFormat(scope.row.AppointDate)
          }}</el-tag>
          <el-tag type="warning">{{ scope.row.AppointTime }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="CheckTypes" label="预约项目" min-width="250">
        <template slot-scope="scope">
          <span v-for="checkType in $config.checkType" :key="checkType.key">
            <el-tag
              type="success"
              v-if="(scope.row.CheckTypes & checkType.value) == checkType.value"
              >{{ checkType.name }}</el-tag
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="Member.CheckSelfHeart.AcceptableStatus"
        label="心血管筛查"
        width="120"
      >
        <template slot-scope="scope">
          <el-tag
            type="info"
            v-if="scope.row.CheckSelfHeart.AcceptableStatus == 0"
            >未评估</el-tag
          >
          <el-tag
            type="success"
            v-if="scope.row.CheckSelfHeart.AcceptableStatus == 10"
            >低危</el-tag
          >
          <el-tag
            type="danger"
            v-if="scope.row.CheckSelfHeart.AcceptableStatus == 20"
            >高危</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="Member.CheckSelfIKDC.IKDCScore"
        label="IKDC自测"
        width="120"
      >
        <template slot-scope="scope">
          <el-tag
            type="warning"
            v-if="scope.row.CheckSelfIKDC && scope.row.Member.CheckSelfIKDC.Key"
            >{{ scope.row.CheckSelfIKDC.IKDCScore }}</el-tag
          >/<el-tag
            type="warning"
            v-if="scope.row.CheckSelfIKDC && scope.row.Member.CheckSelfIKDC.Key"
            >{{ scope.row.CheckSelfIKDC.IKDCResult }}</el-tag
          >
          <el-tag type="info" v-else>未评估</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="Member.CheckSelfVAS.Count"
        label="VAS自测"
        width="120"
      >
        <template slot-scope="scope">
          <el-tag
            type="warning"
            v-if="scope.row.CheckSelfVAS && scope.row.CheckSelfVAS.Key"
            >{{ scope.row.CheckSelfVAS.Count }}处疼痛</el-tag
          >
          <el-tag type="info" v-else>未评估</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="ImproveTypes" v-if="filter && filter.status && filter.status == 'Done'" label="改善方案" min-width="250">
        <template slot-scope="scope">
          <span
            v-for="improveType in $config.improveType"
            :key="improveType.key"
          >
            <el-tag
              type="warning"
              v-if="
                (scope.row.ImproveTypes & improveType.value) ==
                improveType.value
              "
              >{{ improveType.name }}</el-tag
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" :width="200" label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            @click="showAnswer(scope.row)"
            v-if="filter.status != 'Done'"
            >查看问卷</el-button
          > -->
          <!--预约可以拒绝或取消-->
          <el-button
            type="text"
            @click="doPass(scope.row)"
            v-if="filter && filter.status && filter.status == 'Create'"
            >审核通过</el-button
          >
          <!-- <el-button
            type="text"
            @click="doRefuse(scope.row)"
            v-if="
              filter &&
              filter.status &&
              filter.status == 'Create'
            "
            >拒绝</el-button
          > -->
          <el-button
            type="text"
            @click="doCancel(scope.row)"
            v-if="
              filter &&
              filter.status &&
              (filter.status == 'Create' || filter.status == 'Appoint')
            "
            >取消评测</el-button
          >
          <el-button
            type="text"
            @click="handleModify(scope.row)"
            v-if="
              filter &&
              filter.status &&
              (filter.status == 'Appoint' || filter.status == 'Create')
            "
            >修改项目</el-button
          >
          <!--现场评测及查看报告-->
          <el-button
            type="text"
            @click="localCheck(scope.row)"
            v-if="
              filter &&
              filter.status &&
              filter.status == 'Appoint' &&
              view == 'Test'
            "
            >现场评测</el-button
          >
          <!--完成-->
          <el-button
            type="text"
            @click="showCheckDetail(scope.row)"
            v-if="filter && filter.status && filter.status == 'Done'"
            >测评详情</el-button
          >
          <el-button
            type="text"
            @click="improve(scope.row)"
            v-if="filter && filter.status && filter.status == 'Done'"
            >改善方案</el-button
          >
          <el-button
            type="text"
            @click="report(scope.row)"
            v-if="filter && filter.status && filter.status == 'Done'"
            >下载报告</el-button
          >
          <!--已拒绝或已取消可执行删除-->
          <el-button
            type="text"
            @click="remove(scope.row)"
            v-if="
              (filter && filter.status && filter.status == 'Refuse') ||
              filter.status == 'Cancel'
            "
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :page-sizes="pageSizeList"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :current-page.sync="pageIndex"
    ></el-pagination>

    <updateCheckStatus
      ref="updateCheckStatus"
      @update="getData"
    ></updateCheckStatus>
    <customerForm ref="customerForm" :readOnly="true"></customerForm>
    <answerViewer ref="answerViewer"></answerViewer>
    <checkReport ref="checkReport" />
    <modifycheck ref="modifycheck" @update="getData" />
    <improveSetting ref="improveSetting"  @update="getData"/>
  </div>
</template>
<script>
import { getPageList, deleteCheck } from "@/api/check/check.js";
import answerViewer from "@/views/customer/components/answerViewer.vue";
import customerForm from "@/views/customer/components/customerForm.vue";
import updateCheckStatus from "./updateCheckStatus.vue";
import modifycheck from "./modifycheck.vue";
import checkReport from "./checkReport.vue";
import improveSetting from './improveSetting.vue'
export default {
  components: {
    answerViewer,
    updateCheckStatus,
    customerForm,
    checkReport,
    modifycheck,
    improveSetting
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    view: {
      default: "Schedule",
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.pageIndex = 1;
        this.getData();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      pageIndex: 1,
      pageSizeList: [10, 20, 30, 50, 80],
      pageSize: 10,
      datas: [],
    };
  },
  methods: {
    getData() {
      this.filter.pageIndex = this.pageIndex;
      this.filter.pageSize = this.pageSize;
      this.loading = true;
      getPageList(this.filter)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.datas = res.Items;
            this.total = res.Total || res.Items.length;
          }
        })
        .catch((ex) => {
          this.loading = false;
          this.total = 0;
          this.datas = [];
        });
    },
    handleModify(row) {
      this.$refs.modifycheck.handleEdit(row);
    },
    localCheck(row) {
      const routeData = this.$router.resolve({
        path: "/local",
        query: {
          key: row.Key,
        },
      });
      window.open(routeData.href, "_blank");
    },
    report(row) {
      this.$refs.checkReport.show(row);
    },
    improve(row){
      this.$refs.improveSetting.show(row);
    },
    remove(row) {
      this.msg.confirm(
        "确认删除嘛？",
        "warning",
        () => {
          let param = {
            key: row.Key,
          };
          deleteCheck(param).then((res) => {
            this.getData();
          });
        },
        () => {
          //
        }
      );
    },
    //通过
    doPass(row) {
      this.$refs.updateCheckStatus.show(row, "Pass");
    },
    //拒绝
    doRefuse(row) {
      this.$refs.updateCheckStatus.show(row, "Refuse");
    },
    //取消
    doCancel(row) {
      this.$refs.updateCheckStatus.show(row, "Cancel");
    },
    //客户信息
    toCustomerForm(key) {
      this.$refs.customerForm.show(key);
    },
    //问卷信息
    showAnswer(row) {
      let heartKey = row.CheckSelfHeart.Key;
      let ikdcKey = row.CheckSelfIKDC.Key;
      let vasKey = row.CheckSelfVAS.Key;
      this.$refs.answerViewer.showByKey(heartKey, ikdcKey, vasKey, "Heart");
    },
    //检测详情;
    showCheckDetail(row) {
      const routeData = this.$router.resolve({
        path: "/local",
        query: {
          key: row.Key,
        },
      });
      window.open(routeData.href, "_blank");
    },
    pageSizeChange(val) {
      var pageSize = val;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },
    pageCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.status {
  align-self: flex-end;
  font-size: 12px;
  font-weight: bold;
  color: #ff862f;
}
.statusdesc {
  align-self: flex-end;
  font-size: 12px;
  color: #797878;
}
.pagination {
  margin-top: 10px;
  text-align: right;
}
.link {
  color: #268bd2;
  text-decoration: underline;
  cursor: pointer;
}
</style>