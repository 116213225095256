var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formVisible
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dragDialog", rawName: "v-dragDialog" }],
              attrs: {
                title: _vm.formTitle,
                visible: _vm.formVisible,
                width: "600px",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.formVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.dataForm,
                    "label-width": "150px",
                    rules: _vm.dataFormRules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开放项目：", prop: "CheckTypes" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "small",
                            multiple: "",
                            placeholder: "请选择开放测试项目"
                          },
                          model: {
                            value: _vm.dataForm.CheckTypes,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "CheckTypes", $$v)
                            },
                            expression: "dataForm.CheckTypes"
                          }
                        },
                        _vm._l(_vm.$config.checkType, function(item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.formVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }