var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", size: "mini", data: _vm.datas }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "Code", fixed: "left", label: "门店", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.Code) + " "),
                    _c("el-tag", { attrs: { type: "warning" } }, [
                      _vm._v(_vm._s(scope.row.Tenant.Name))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "客户", fixed: "left", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.Member
                      ? _c(
                          "div",
                          {
                            staticClass: "link",
                            on: {
                              click: function($event) {
                                return _vm.toCustomerForm(scope.row.Member.Key)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(scope.row.Member.RealName) + " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("el-tag", { attrs: { type: "info" } }, [
                      _vm._v(_vm._s(scope.row.Member.Mobile))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "Member.Sex", label: "性别", width: 60 }
          }),
          _c("el-table-column", {
            attrs: { prop: "BMI", label: "BMI", "min-width": 70 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.BMI.toFixed(2)) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "FatRate", label: "体脂率", "min-width": 70 }
          }),
          _c("el-table-column", {
            attrs: { label: "血压", "min-width": 120 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-tag", { attrs: { type: "warning" } }, [
                      _vm._v(_vm._s(scope.row.BloodPressureMin))
                    ]),
                    _vm._v("/ "),
                    _c("el-tag", { attrs: { type: "danger" } }, [
                      _vm._v(_vm._s(scope.row.BloodPressureMax))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "预约日期", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-tag", { attrs: { type: "warning" } }, [
                      _vm._v(
                        _vm._s(_vm.format.dateFormat(scope.row.AppointDate))
                      )
                    ]),
                    _c("el-tag", { attrs: { type: "warning" } }, [
                      _vm._v(_vm._s(scope.row.AppointTime))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "CheckTypes",
              label: "预约项目",
              "min-width": "250"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(_vm.$config.checkType, function(checkType) {
                    return _c(
                      "span",
                      { key: checkType.key },
                      [
                        (scope.row.CheckTypes & checkType.value) ==
                        checkType.value
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(_vm._s(checkType.name))
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Member.CheckSelfHeart.AcceptableStatus",
              label: "心血管筛查",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.CheckSelfHeart.AcceptableStatus == 0
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未评估")
                        ])
                      : _vm._e(),
                    scope.row.CheckSelfHeart.AcceptableStatus == 10
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("低危")
                        ])
                      : _vm._e(),
                    scope.row.CheckSelfHeart.AcceptableStatus == 20
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("高危")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Member.CheckSelfIKDC.IKDCScore",
              label: "IKDC自测",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.CheckSelfIKDC &&
                    scope.row.Member.CheckSelfIKDC.Key
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v(_vm._s(scope.row.CheckSelfIKDC.IKDCScore))
                        ])
                      : _vm._e(),
                    _vm._v("/"),
                    scope.row.CheckSelfIKDC &&
                    scope.row.Member.CheckSelfIKDC.Key
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v(_vm._s(scope.row.CheckSelfIKDC.IKDCResult))
                        ])
                      : _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未评估")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Member.CheckSelfVAS.Count",
              label: "VAS自测",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.CheckSelfVAS && scope.row.CheckSelfVAS.Key
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v(
                            _vm._s(scope.row.CheckSelfVAS.Count) + "处疼痛"
                          )
                        ])
                      : _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未评估")
                        ])
                  ]
                }
              }
            ])
          }),
          _vm.filter && _vm.filter.status && _vm.filter.status == "Done"
            ? _c("el-table-column", {
                attrs: {
                  prop: "ImproveTypes",
                  label: "改善方案",
                  "min-width": "250"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(_vm.$config.improveType, function(
                          improveType
                        ) {
                          return _c(
                            "span",
                            { key: improveType.key },
                            [
                              (scope.row.ImproveTypes & improveType.value) ==
                              improveType.value
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v(_vm._s(improveType.name))
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  748629414
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              width: 200,
              label: "操作",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.filter &&
                    _vm.filter.status &&
                    _vm.filter.status == "Create"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.doPass(scope.row)
                              }
                            }
                          },
                          [_vm._v("审核通过")]
                        )
                      : _vm._e(),
                    _vm.filter &&
                    _vm.filter.status &&
                    (_vm.filter.status == "Create" ||
                      _vm.filter.status == "Appoint")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.doCancel(scope.row)
                              }
                            }
                          },
                          [_vm._v("取消评测")]
                        )
                      : _vm._e(),
                    _vm.filter &&
                    _vm.filter.status &&
                    (_vm.filter.status == "Appoint" ||
                      _vm.filter.status == "Create")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleModify(scope.row)
                              }
                            }
                          },
                          [_vm._v("修改项目")]
                        )
                      : _vm._e(),
                    _vm.filter &&
                    _vm.filter.status &&
                    _vm.filter.status == "Appoint" &&
                    _vm.view == "Test"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.localCheck(scope.row)
                              }
                            }
                          },
                          [_vm._v("现场评测")]
                        )
                      : _vm._e(),
                    _vm.filter &&
                    _vm.filter.status &&
                    _vm.filter.status == "Done"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.showCheckDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v("测评详情")]
                        )
                      : _vm._e(),
                    _vm.filter &&
                    _vm.filter.status &&
                    _vm.filter.status == "Done"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.improve(scope.row)
                              }
                            }
                          },
                          [_vm._v("改善方案")]
                        )
                      : _vm._e(),
                    _vm.filter &&
                    _vm.filter.status &&
                    _vm.filter.status == "Done"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.report(scope.row)
                              }
                            }
                          },
                          [_vm._v("下载报告")]
                        )
                      : _vm._e(),
                    (_vm.filter &&
                      _vm.filter.status &&
                      _vm.filter.status == "Refuse") ||
                    _vm.filter.status == "Cancel"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.remove(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticClass: "pagination",
        attrs: {
          background: "",
          "page-sizes": _vm.pageSizeList,
          "page-size": _vm.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
          "current-page": _vm.pageIndex
        },
        on: {
          "size-change": _vm.pageSizeChange,
          "current-change": _vm.pageCurrentChange,
          "update:currentPage": function($event) {
            _vm.pageIndex = $event
          },
          "update:current-page": function($event) {
            _vm.pageIndex = $event
          }
        }
      }),
      _c("updateCheckStatus", {
        ref: "updateCheckStatus",
        on: { update: _vm.getData }
      }),
      _c("customerForm", { ref: "customerForm", attrs: { readOnly: true } }),
      _c("answerViewer", { ref: "answerViewer" }),
      _c("checkReport", { ref: "checkReport" }),
      _c("modifycheck", { ref: "modifycheck", on: { update: _vm.getData } }),
      _c("improveSetting", {
        ref: "improveSetting",
        on: { update: _vm.getData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }